import {Category} from '../../Services/Tools/interfaces';

export const SET_CATEGORIES = 'ADD_CATEGORIES';

const initialState = null as Category[] | null;

const categoriesReducer = (categories = initialState, action: any) => {
  switch (action.type) {
    case SET_CATEGORIES:
      return action.payload;
  }
  return categories;
};

export default categoriesReducer;
