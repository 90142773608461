import React, {useEffect, useState} from 'react'
import {withFirebase} from "./Firebase";
import {useDispatch, useSelector} from "react-redux";
import {UPDATE_PROPOSAL} from "../redux/reducers/proposalsReducer";
import {Category, Proposal} from "../Services/Tools/interfaces";
import colors from "../assets/colors";
import {Categories, Results} from "../config/routing/routes";
import down from "../assets/images/icons/Down.png";

const ProposalsCategoryScreen = (props: any) => {
  const dispatch = useDispatch();
  const proposalsSaved = useSelector((state: any) => state.proposals);
  const categoriesStored = useSelector((state: any) => state.categories);
  const [proposals, setProposals] = useState<Proposal[]>([])
  const [category, setCategory] = useState<Category | null>(null)

  useEffect(() => {
    setCategory(categoriesStored.find((category: Category) => category.name === props.match.params.category))
    setProposals(proposalsSaved.filter((proposal: Proposal) => proposal.category === props.match.params.category))
  }, [])

  const updateProposal = (proposal: Proposal, score: number) => {
    proposal.score = score;

    dispatch({type: UPDATE_PROPOSAL, payload: proposal});
  }

  const renderProposal = (proposal: Proposal) => {
    return (
        <li className="container__row" style={{marginBottom: 40}} key={proposal.proposal}>
          <div className="container__col-6 text-align-center">
            <h5 style={{marginBottom: 31}}>{proposal.proposal}</h5>
            <div className={"flex flex-align-center-vertically flex-align-center"}>
              <span>Pas d'accord</span>
              <button className={"choice_button"}
                      style={{
                        borderColor: colors.red,
                        marginLeft: 16,
                        marginRight: 16,
                        backgroundColor: proposal.score === -3 ? colors.red : "transparent"
                      }} onClick={async () => {
                updateProposal(proposal, -3);
              }}/>
              <button className={"choice_button"}
                      style={{
                        borderColor: colors.blood_orange,
                        marginRight: 16,
                        width: 35,
                        height: 35,
                        backgroundColor: proposal.score === -2 ? colors.blood_orange : "transparent"
                      }}
                      onClick={async () => {
                        updateProposal(proposal, -2);
                      }}/>
              <button className={"choice_button"}
                      style={{
                        borderColor: colors.orange,
                        marginRight: 16,
                        width: 28,
                        height: 28,
                        backgroundColor: proposal.score === -1 ? colors.orange : "transparent"
                      }}
                      onClick={async () => {
                        updateProposal(proposal, -1);
                      }}/>
              <button className={"choice_button"}
                      style={{
                        borderColor: colors.dark[20],
                        marginRight: 16,
                        width: 25,
                        height: 25,
                        backgroundColor: proposal.score === 0 ? colors.dark[20] : "transparent"
                      }}
                      onClick={async () => {
                        updateProposal(proposal, 0);
                      }}/>
              <button className={"choice_button"}
                      style={{
                        borderColor: colors.green_light,
                        marginRight: 16,
                        width: 28,
                        height: 28,
                        backgroundColor: proposal.score === 1 ? colors.green_light : "transparent"
                      }}
                      onClick={async () => {
                        updateProposal(proposal, 1);
                      }}/>
              <button className={"choice_button"}
                      style={{
                        borderColor: colors.green_normal,
                        marginRight: 16,
                        width: 35,
                        height: 35,
                        backgroundColor: proposal.score === 2 ? colors.green_normal : "transparent"
                      }}
                      onClick={async () => {
                        updateProposal(proposal, 2);
                      }}/>
              <button className={"choice_button"} style={{
                borderColor: colors.green_dark,
                marginRight: 16,
                backgroundColor: proposal.score === 3 ? colors.green_dark : "transparent"
              }}
                      onClick={async () => {
                        updateProposal(proposal, 3);
                      }}/>
              <span>D'accord</span>
            </div>
          </div>
        </li>
    )
  }

  if (!proposals) {
    return <p>Chargement en cours...</p>;
  }

  const displayTime = () => {
    const nbProposals = proposals.length
    const nbProposalsAnswered = proposals.filter((proposal: Proposal) => proposal.score !== undefined).length

    if (nbProposals === 0 || nbProposalsAnswered == nbProposals) {
      return;
    }

    const nbMinutes = Math.round((nbProposals - nbProposalsAnswered) * 15 / 60);

    if (nbProposalsAnswered) {
      return (
          <div>
            <p className={"text-align-center"}
               style={{borderColor: colors.dark[100], borderRadius: 4, borderWidth: 1, border: "solid", padding: "0 16px"}}>Temps restant
              : <strong>environ {nbMinutes} {nbMinutes <= 1 ? "minute" : "minutes"}</strong></p>
          </div>
      )
    } else {
      return (
          <div className={"text-align-center"}>
            <p style={{borderColor: colors.dark[100], borderRadius: 4, borderWidth: 1, border: "solid", padding: "0 16px"}}>Temps moyen de
              complétion : <strong>{nbMinutes} {nbMinutes <= 1 ? "minute" : "minutes"}</strong></p>
          </div>
      )
    }
  }

  return (
      <div>
        <div className={`container container__row`}>
          <a onClick={async () => {
            props.history.goBack()
          }} className={"flex flex-align-center-vertically pt-1 pb-1"} style={{cursor: "pointer"}}>
            <img src={down}/>
            Revenir aux categories
          </a>
        </div>
        <div className="container container__row__padding-md" style={{marginTop: 48}}>
          <div className={`container__row`} style={{marginBottom: 80}}>
            <div className={`container__col-6`}>
              <h3 className={"pb-3 text-align-center"}>{category ? category.name : ""}</h3>
              <div className={"flex flex-align-center"}>
                <div>
                  {displayTime()}
                </div>
              </div>
              <ul style={{marginTop: 48}}>
                {proposals.map((proposal: Proposal) => {
                  return renderProposal(proposal)
                })}
              </ul>
            </div>
          </div>
          <div className={`container__row fixed-bottom`} style={{
            backgroundColor: "white",
            boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)"
          }}>
            <div className={`container__col-6 flex flex-align-center flex-align-center-vertically`}
                 style={{padding: 16}}>
              <p>
                <strong>{proposals.filter((proposal: { score: undefined | number; }) => proposal.score !== undefined).length}/{proposals.length}</strong> propositions
              </p>
              <button className={"btn btn-dark"} style={{marginLeft: 32}} onClick={async () => {
                props.history.goBack()
              }}>Valider mes resultats
              </button>
            </div>
          </div>
        </div>
      </div>
  )
}

export default withFirebase(ProposalsCategoryScreen)