import React from 'react'
import {withFirebase} from "./Firebase";
import {Categories, Proposals} from "../config/routing/routes";
import {useSelector} from "react-redux";
import vote from "../assets/images/vote.png";

const WelcomeScreen = (props: any) => {
  const proposals = useSelector((state: any) => state.proposals);

  return (
      <div>
        <div className="container container__row__padding-sm" style={{paddingTop: 105}}>
          <div className={`container__row flex flex-align-center-vertically`}>
            <div className={`container__col-3 sm-hidden`} style={{paddingRight: 115}}>
              <img src={vote} alt=""/>
            </div>
            <div className={`container__col-md-6 container__col-xl-3`}>
              <h3 className={"pb-5"}>Quels candidats se rapprochent le plus de vos convictions ?</h3>
              <h5 className={"pb-2"}>Le principe est simple</h5>
              <p className={"pb-2"}>Sur la base des <a href="https://www.lemonde.fr/les-decodeurs/article/2022/02/16/election-presidentielle-2022-comparez-les-programmes-des-principaux-candidats_6113964_4355770.html#usagedurfrendum">propositions relevées par le journal "Le Monde"</a>, vous vous positionnez sur les propositions, allant de "D'accord" à "Pas d'accord", en passant par le neutre. </p>
              <p className={"pb-2"}>En fonction de votre réponse, des points sont ajoutés ou retirés aux candidats derriere la proposition.</p>
              <p className={"pb-2"}>À la fin, les candidats sont classés selon vos proximités politiques.</p>
              <p className={"pb-5"}>Nous ne stockons pas vos données sur nos serveurs.</p>
              <button className={"btn btn-dark mb-6"} onClick={async () => {
                props.history.push(Categories)
              }}>Commencer
              </button>
              <div>
                <small >Contact : <strong><a href="mailto:hello@which-one.fr">hello@which-one.fr</a></strong></small>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default withFirebase(WelcomeScreen)