import app from 'firebase'
import config from '../../config/config'
import Auth = app.auth.Auth;
import Firestore = app.firestore.Firestore;
import Functions = app.functions.Functions;

class Firebase {
  auth: Auth | null
  firestore: Firestore | null
  functions: Functions | null

  constructor() {
    app.initializeApp(config.firebase)
    this.auth = app.auth()
    this.firestore = app.firestore()
    this.functions = app.functions()
  }

  signInWithGoogle = async () => {
    if (!this.auth) {
      return;
    }

    const provider = new app.auth.GoogleAuthProvider()
    provider.setCustomParameters({prompt: 'select_account'})

    return await this.auth.signInWithPopup(provider)
  }

  signOut = () => {
    if (!this.auth) {
      return;
    }

    this.auth.signOut().then()
  }
}

export default Firebase