import React from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import AppContainer from './Components/AppContainer'
import {withFirebase} from './Components/Firebase'

const App = (props: any) => {

  return (
      <main>
        <Router>
          <AppContainer/>
        </Router>
      </main>
  )
}

export default withFirebase(App)
