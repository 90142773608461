import {
 Welcome,
 Proposals,
 Results,
 Categories,
 ProposalsCategory, ProposalsCandidate,
} from './routes'
import WelcomeScreen from '../../Components/WelcomeScreen'
import ProposalsScreen from '../../Components/ProposalsScreen'
import ProposalsCategoryScreen from '../../Components/ProposalsCategoryScreen'
import ResultsScreen from '../../Components/ResultsScreen'
import CategoriesScreen from '../../Components/CategoriesScreen'
import ProposalsCandidateScreen from '../../Components/ProposalsCandidateScreen'

const router = [
 {
  path: Welcome,
  component: WelcomeScreen,
  exact: true,
 },
 {
  path: Categories,
  component: CategoriesScreen,
  exact: true,
 },
 {
  path: Proposals,
  component: ProposalsScreen,
  exact: true,
 },
 {
  path: ProposalsCategory,
  component: ProposalsCategoryScreen,
  exact: true,
 },
 {
  path: ProposalsCandidate,
  component: ProposalsCandidateScreen,
  exact: true,
 },
 {
  path: Results,
  component: ResultsScreen,
  exact: true,
 },
]

export default router
