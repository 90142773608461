import React from 'react'
import {Switch, Route} from 'react-router-dom'
import router from '../config/routing/router'
import '../assets/scss/reset.scss'
import '../assets/scss/global.scss'
import {useSelector} from "react-redux";


const AppContainer = () => {
  return (
      <Switch>
        {router.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
  )
}

function RouteWithSubRoutes(route: any) {
  return (
      <Route
          path={route.path}
          render={props => (
              <route.component {...props} routes={route.routes}/>
          )}
      />
  )
}

export default AppContainer
