import {Category, Proposal} from "./Tools/interfaces";
import {SET_PROPOSALS} from "../redux/reducers/proposalsReducer";
import {SET_CATEGORIES} from "../redux/reducers/categoriesReducer";

export const getProposals = async (firestore: any, dispatch: any, categories: Category[]) => {
  const query = firestore.collection('proposals').get();

  query.then((result: { docs: any; }) => {
    const proposalsResult = result.docs
    const proposalsFormatted = [] as Proposal[];

    for (const proposal of proposalsResult) {
      const proposalData = proposal.data() as Proposal
      proposalData.id = proposal.id;

      proposalsFormatted.push(proposalData);
    }

    proposalsFormatted.sort((a: Proposal, b: Proposal) => {
      const bCategoryIndex = categories.findIndex((category: Category) => category.name === b.category)
      const aCategoryIndex = categories.findIndex((category: Category) => category.name === a.category)

      return aCategoryIndex - bCategoryIndex;
    });

    dispatch({type: SET_PROPOSALS, payload: proposalsFormatted});
  });
}

export const getCategories = async (firestore: any, dispatch: any) => {
  const queryCategories = firestore.collection('categories').get();
  const tmpCategories = [] as Category[]

  queryCategories.then((resultCategories: { docs: any; }) => {
    for (const categoryDocument of resultCategories.docs) {
      if (categoryDocument.get("nbProposals") > 0) {
        tmpCategories.push(categoryDocument.data() as Category);
      }
    }

    tmpCategories.sort((a: Category, b: Category) => {
      if (a.nbProposals !== b.nbProposals) {
        return b.nbProposals - a.nbProposals;
      } else {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }
    });

    dispatch({type: SET_CATEGORIES, payload: tmpCategories});
  });

  return tmpCategories;
}