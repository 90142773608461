import React, {useEffect, useState} from 'react'
import {withFirebase} from "./Firebase";
import {useSelector} from "react-redux";
import {Candidate, Proposal} from "../Services/Tools/interfaces";
import down from "../assets/images/icons/Down.png";
import colors from "../assets/colors";
import firebase from "firebase";

const ProposalsCandidateScreen = (props: any) => {
  const proposalsSaved = useSelector((state: any) => state.proposals);
  const [proposals, setProposals] = useState<Proposal[]>([])
  const [candidate, setCandidate] = useState<Candidate | null>(null)

  useEffect(() => {
    const queryCandidate = props.firebase.firestore.collection('candidates').where("name", "==", props.match.params.candidate).get();

    queryCandidate.then((resultCandidate: { docs: any; }) => {
      setCandidate(resultCandidate.docs[0].data() as Candidate);
    });

    setProposals(proposalsSaved.filter((proposal: Proposal) => proposal.candidates.find((candidate) => candidate === props.match.params.candidate) && proposal.score !== undefined))
  }, [])

  const renderProposal = (proposal: Proposal) => {
    return (
        <li className="container__row" key={proposal.proposal}>
          <div className="container__col-6">
            <p>- {proposal.proposal}</p>
          </div>
        </li>
    )
  }

  if (!candidate) {
    return <p>Chargement...</p>
  }

  return (
      <div>
        <div className={`container container__row`}>
          <a onClick={async () => {
            props.history.goBack()
          }} className={"flex flex-align-center-vertically pt-1 pb-1"} style={{cursor: "pointer"}}>
            <img src={down}/>
            Revenir aux candidats
          </a>
        </div>
        <div className={"flex flex-align-center pt-10 pb-2"}>
          <img src={candidate.photo} style={{width: 81, height: 81, borderRadius: "50%", objectFit: "cover"}}/>
        </div>
        <h2 className={"text-align-center pb-3"}>{props.match.params.candidate}</h2>
        <div className="container">
          <div className={"flex pb-6"} style={{maxWidth: "100%"}}>
            <div style={{
              height: 16,
              backgroundColor: colors.green_dark,
              flex: proposals.filter((proposal) => proposal.score === 3).length
            }}/>
            <div style={{
              height: 16,
              backgroundColor: colors.green_normal,
              flex: proposals.filter((proposal) => proposal.score === 2).length
            }}/>
            <div style={{
              height: 16,
              backgroundColor: colors.green_light,
              flex: proposals.filter((proposal) => proposal.score === 1).length
            }}/>
            <div style={{
              height: 16,
              backgroundColor: colors.dark[20],
              flex: proposals.filter((proposal) => proposal.score === 0).length
            }}/>
            <div style={{
              height: 16,
              backgroundColor: colors.orange,
              flex: proposals.filter((proposal) => proposal.score === -1).length
            }}/>
            <div style={{
              height: 16,
              backgroundColor: colors.blood_orange,
              flex: proposals.filter((proposal) => proposal.score === -2).length
            }}/>
            <div style={{
              height: 16,
              backgroundColor: colors.red,
              flex: proposals.filter((proposal) => proposal.score === -3).length
            }}/>
          </div>
        </div>
        <div className="container container__row__padding-md pb-3">
          <div className={`container__row`}>
            <div className={`container__col-6`}>
              <ul>
                <h5 className={"pb-1"} style={{color: colors.green_dark}}>Totalement d'accord</h5>
                {proposals.filter((proposal) => proposal.score === 3).map((proposal: Proposal) => {
                  return renderProposal(proposal)
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="container container__row__padding-md pb-3">
          <div className={`container__row`}>
            <div className={`container__col-6`}>
              <ul>
                <h5 className={"pb-1"} style={{color: colors.green_normal}}>D'accord</h5>
                {proposals.filter((proposal) => proposal.score === 2).map((proposal: Proposal) => {
                  return renderProposal(proposal)
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="container container__row__padding-md pb-3">
          <div className={`container__row`}>
            <div className={`container__col-6`}>
              <ul>
                <h5 className={"pb-1"} style={{color: colors.green_light}}>En partie d'accord</h5>
                {proposals.filter((proposal) => proposal.score === 1).map((proposal: Proposal) => {
                  return renderProposal(proposal)
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="container container__row__padding-md pb-3">
          <div className={`container__row`}>
            <div className={`container__col-6`}>
              <ul>
                <h5 className={"pb-1"} style={{color: colors.dark["30"]}}>Neutre</h5>
                {proposals.filter((proposal) => proposal.score === 0).map((proposal: Proposal) => {
                  return renderProposal(proposal)
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="container container__row__padding-md pb-3">
          <div className={`container__row`}>
            <div className={`container__col-6`}>
              <ul>
                <h5 className={"pb-1"} style={{color: colors.orange}}>En partie en désaccord</h5>
                {proposals.filter((proposal) => proposal.score === -1).map((proposal: Proposal) => {
                  return renderProposal(proposal)
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="container container__row__padding-md pb-3">
          <div className={`container__row`}>
            <div className={`container__col-6`}>
              <ul>
                <h5 className={"pb-1"} style={{color: colors.blood_orange}}>Pas d'accord</h5>
                {proposals.filter((proposal) => proposal.score === -2).map((proposal: Proposal) => {
                  return renderProposal(proposal)
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="container container__row__padding-md pb-3">
          <div className={`container__row`}>
            <div className={`container__col-6`}>
              <ul>
                <h5 className={"pb-1"} style={{color: colors.red}}>Pas du tout d'accord</h5>
                {proposals.filter((proposal) => proposal.score === -3).map((proposal: Proposal) => {
                  return renderProposal(proposal)
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
  )
}

export default withFirebase(ProposalsCandidateScreen)