import {Proposal} from '../../Services/Tools/interfaces';

export const SET_PROPOSALS = 'ADD_PROPOSALS';
export const CLEAR_PROPOSALS = 'CLEAR_PROPOSALS';
export const UPDATE_PROPOSALS = 'UPDATE_PROPOSALS';
export const UPDATE_PROPOSAL = 'UPDATE_PROPOSAL';

const initialState = null as Proposal[] | null;

const proposalsReducer = (proposals = initialState, action: any) => {
  switch (action.type) {
    case SET_PROPOSALS:
      return action.payload;
    case CLEAR_PROPOSALS:
      return null;
    case UPDATE_PROPOSALS:
      if (!proposals) {
        return;
      }

      return action.payload;
    case UPDATE_PROPOSAL:
      if (!proposals) {
        return;
      }

      const newProposals = proposals.map(proposal => proposal.proposal === action.payload.proposal ?
          // transform the one with a matching id
          {...proposal, score: action.payload.score} :
          // otherwise return original todo
          proposal
      )
      return newProposals;
  }
  return proposals;
};

export default proposalsReducer;
