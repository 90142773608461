export default {
  dark: {
    100: '#0E0A25',
    90: '#26233B',
    80: '#3E3B50',
    70: '#565366',
    60: '#6E6C7C',
    50: '#878492',
    40: '#9F9DA8',
    30: '#B7B6BE',
    20: '#CFCED3',
    10: '#E7E7E9',
    5: '#F3F3F4',
  },
  green: {
    100: '#11650D',
    90: '#297424',
    80: '#41843D',
    70: '#589356',
    60: '#70A36E',
    50: '#88B286',
    40: '#A0C19E',
    30: '#B8D1B6',
    20: '#CFE0CF',
    10: '#E7F0E7',
    5: '#F3F7F3',
  },
  white: '#FFFFFF',
  red: '#FE0000',
  blood_orange: '#F37020',
  orange: '#FCB913',
  green_light: '#C0D731',
  green_normal: '#50B849',
  green_dark: '#00A654',
};
