import globalConfig from './globalConfig'

const config = {
  ...globalConfig,
  firebase: {
    apiKey: "AIzaSyDUxmB9Insok1lnbKiIV0HRLwvUHAPPpHU",
    authDomain: "wichone-94ba5.firebaseapp.com",
    projectId: "wichone-94ba5",
    storageBucket: "wichone-94ba5.appspot.com",
    messagingSenderId: "505714447589",
    appId: "1:505714447589:web:89a431005a98aff72cf00c"
  },
}

export default config