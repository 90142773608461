import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Firebase, {FirebaseContext} from './Components/Firebase'
import {Provider as StoreProvider} from 'react-redux';
import configureStore from './redux/configureStore';
import { PersistGate } from 'redux-persist/integration/react'

ReactDOM.render(
    <StoreProvider store={configureStore.store}>
      <PersistGate loading={null} persistor={configureStore.persistor}>
        <FirebaseContext.Provider value={new Firebase()}>
          <React.StrictMode>
            <App/>
          </React.StrictMode>
        </FirebaseContext.Provider>
      </PersistGate>
    </StoreProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
