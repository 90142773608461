import {combineReducers, createStore} from 'redux'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import proposalsReducer from "./reducers/proposalsReducer";
import categoriesReducer from "./reducers/categoriesReducer"; // defaults to localStorage for web

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  proposals: proposalsReducer,
  categories: categoriesReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

let store = createStore(persistedReducer)
let persistor = persistStore(store)

const configureStore = {store, persistor}

export default configureStore