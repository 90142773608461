import React, {useEffect, useState} from 'react'
import {withFirebase} from "./Firebase";
import {Category, Proposal} from "../Services/Tools/interfaces";
import {ProposalsCategory, Results} from "../config/routing/routes";
import {useDispatch, useSelector} from "react-redux";
import {getCategories, getProposals} from "../Services/proposals";

const CategoriesScreen = (props: any) => {
  const dispatch = useDispatch();

  const categories = useSelector((state: any) => state.categories);
  const proposals = useSelector((state: any) => state.proposals);

  useEffect(() => {
    if (!categories) {

      getCategories(props.firebase.firestore, dispatch).then((tmpCategories) => {
        if (!proposals) {
          getProposals(props.firebase.firestore, dispatch, tmpCategories).then()
        }
      })
    } else {
      if (!proposals) {
        getProposals(props.firebase.firestore, dispatch, categories).then()
      }
    }
  }, [])

  if (!categories || !proposals) {
    return <p>Chargement en cours...</p>;
  }

  const renderCategory = (category: Category) => {
    const nbProposals = proposals.filter((proposal: Proposal) => proposal.category === category.name).length
    const nbProposalsAnswered = proposals.filter((proposal: Proposal) => proposal.category === category.name && proposal.score !== undefined).length

    if (nbProposals === 0) {
      return;
    }

    const nbMinutes = Math.round((nbProposals - nbProposalsAnswered) * 15 / 60);

    const displayTime = () => {
      if (nbProposals === nbProposalsAnswered) {
        return (<p className={"pb-2"} style={{color: "white"}}>n</p>);
      } else {
        if (nbProposalsAnswered) {
          return (
              <p className={"pb-2"}>Environ {nbMinutes} {nbMinutes <= 1 ? "minute restante" : "minutes restantes"}</p>)
        } else {
          return (<p className={"pb-2"}>Environ {nbMinutes} {nbMinutes <= 1 ? "minute" : "minutes"}</p>)
        }
      }
    }

    const displayButton = () => {
      if (nbProposalsAnswered) {
        return (
            <button className={"btn btn-white small-btn"} onClick={async () => {
              props.history.push(ProposalsCategory.replace(":category", category.name))
            }}>Modifier
            </button>
        )
      }

      return (
          <button className={"btn btn-dark small-btn"} onClick={async () => {
            props.history.push(ProposalsCategory.replace(":category", category.name))
          }}>Compléter
          </button>
      )
    }

    return (
        <div className="container__col-6 container__col-xl-2" key={category.name}>
          <div className={"flex flex-align-center-vertically"}
               style={{padding: "0 28px", border: "1px solid #CFCED3", borderRadius: 4, margin: 12, minHeight: 240}}>
            <div>
              <h5 className={"pb-2"} style={{fontWeight: "bold"}}>{category.name}</h5>
              <p className={"pb-1"}>
                <strong>{nbProposalsAnswered}/{nbProposals}</strong> {nbProposals > 1 ? "propositions complétées" : "proposition complétée"}
              </p>
              {displayTime()}
              {displayButton()}
            </div>
          </div>
        </div>
    )
  }

  return (
      <div>
        <div className="container container__row__padding-md" style={{marginTop: 87, marginBottom: 120}}>
          <div className={`container__row`}>
            <div className={`container__col-6`}>
              <div className={"text-align-center"}>
                <h3>Catégories des propositions</h3>
                <p className={"pt-3"}>Vous n'êtes pas obligé de compléter toutes les propositions<br/>pour accéder aux
                  résultats</p>
              </div>
            </div>
          </div>
          <div className={`container__row`} style={{paddingTop: 64}}>
            {categories.map((category: Category) => {
              return renderCategory(category)
            })}
          </div>
          <div className={`container__row fixed-bottom`} style={{
            backgroundColor: "white",
            boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)"
          }}>
            <div className={`container__col-6 flex flex-align-center flex-align-center-vertically`}
                 style={{padding: 16}}>
              <button className={"btn btn-dark"} onClick={async () => {
                props.history.push(Results)
              }}>Voir mes résultats
              </button>
              <p style={{
                position: "absolute",
                right: "5%"
              }}>
                <a href="#" onClick={async () => {
                  getCategories(props.firebase.firestore, dispatch).then((tmpCategories) => {
                    getProposals(props.firebase.firestore, dispatch, tmpCategories).then()
                  })
                }}>Tout remettre à zéro et à jour</a>
              </p>
            </div>
          </div>
        </div>
      </div>
  )
}

export default withFirebase(CategoriesScreen)