import React, {CSSProperties, useEffect, useState} from 'react'
import {withFirebase} from "./Firebase";
import {useSelector} from "react-redux";
import {Candidate, Proposal} from "../Services/Tools/interfaces";
import {Categories, ProposalsCandidate} from "../config/routing/routes";
import down from "../assets/images/icons/Down.png";
import colors from "../assets/colors";

const ResultsScreen = (props: any) => {
  const proposals = useSelector((state: any) => state.proposals);
  const categories = useSelector((state: any) => state.categories);
  const [candidates, setCandidates] = useState<any[]>([])
  const [candidatesByCategory, setCandidatesByCategory] = useState<any[]>([])
  const [resultType, setResultType] = useState<string>("total")
  const [mostCandidatesAgreed, setMostCandidatesAgreed] = useState<any[]>([])
  const [candidatesFilter, setCandidatesFilter] = useState<any[]>([])

  useEffect(() => {
    const queryCandidates = props.firebase.firestore.collection('candidates').orderBy("name", "asc").get();

    queryCandidates.then((resultCandidate: { docs: any; }) => {
      formatScoreByCandidate(resultCandidate).then()
      formatScoreByCandidateByCategory(resultCandidate).then()
    });

  }, [])

  const formatScoreByCandidate = async (resultCandidate: { docs: any; }) => {
    const tmpCandidates: Candidate[] = [];

    for (const candidate of resultCandidate.docs) {
      tmpCandidates.push({
        name: candidate.get("name"),
        score: 0,
        photo: candidate.get("photo"),
        group: candidate.get("group"),
      });
    }

    for (const proposal of proposals.filter((proposal: { score: undefined; }) => proposal.score !== undefined)) {
      for (const proposalCandidate of proposal.candidates) {
        const candidateAssociated = tmpCandidates.find(tmpCandidate => tmpCandidate.name === proposalCandidate);

        if (candidateAssociated) {
          candidateAssociated.score += proposal.score;
        }
      }
    }

    tmpCandidates.sort(function (a, b) {
      return b.score - a.score;
    });

    const tmpFilter = [];

    // @ts-ignore
    for (const [i, candidate] of tmpCandidates.entries()) {
      tmpFilter.push({
        candidate: candidate,
        active: i < 3
      })
    }

    setCandidatesFilter(tmpFilter)
    setCandidates(tmpCandidates)
  }

  const formatScoreByCandidateByCategory = async (resultCandidate: { docs: any; }) => {
    const tmpCandidatesByCategory: any[] = [];

    for (const category of categories) {
      const candidatesScore: any[] = [];

      for (const candidate of resultCandidate.docs) {
        candidatesScore.push({
          name: candidate.get("name"),
          score: 0,
          photo: candidate.get("photo"),
          group: candidate.get("group"),
          nbProposals: 0,
          percent: 0,
        });
      }

      tmpCandidatesByCategory.push({
        name: category.name,
        nbProposals: category.nbProposals,
        candidates: candidatesScore,
      })
    }

    for (const proposal of proposals.filter((proposal: { score: undefined; }) => proposal.score !== undefined)) {
      const categoryAssociated = tmpCandidatesByCategory.find(category => category.name === proposal.category);

      if (categoryAssociated) {
        for (const proposalCandidate of proposal.candidates) {
          const candidateAssociated = categoryAssociated.candidates.find((candidate: { name: any; }) => candidate.name === proposalCandidate);

          if (candidateAssociated) {
            candidateAssociated.score += proposal.score;
            candidateAssociated.nbProposals++;
          }
        }
      }
    }

    for (const category of tmpCandidatesByCategory) {
      for (const candidate of category.candidates) {
        if (candidate.nbProposals > 0) {
          candidate.percent = 100 * (candidate.score + candidate.nbProposals * 3) / (candidate.nbProposals * 3 * 2);
        }
      }

      category.candidates.sort(function (a: { percent: number; }, b: { percent: number; }) {
        return a.percent - b.percent;
      });
    }

    const tmpMostCandidatesAgreed = [];

    for (const candidate of resultCandidate.docs) {
      tmpMostCandidatesAgreed.push({
        name: candidate.get("name"),
        score: 0,
        photo: candidate.get("photo"),
        group: candidate.get("group"),
      });
    }

    for (const category of tmpCandidatesByCategory) {
      const array = category.candidates.filter((candidateArray: { percent: number; nbProposals: number }) => candidateArray.percent >= 50 && candidateArray.nbProposals > 0);

      for (const [i, candidate] of array.entries()) {
        const candidateAssociated = tmpMostCandidatesAgreed.find((candidateArray: { name: any; }) => candidate.name === candidateArray.name);

        if (candidateAssociated) {
          candidateAssociated.score += i + 1;
        }
      }
    }

    tmpMostCandidatesAgreed.sort(function (a: { score: number; }, b: { score: number; }) {
      return b.score - a.score;
    });

    console.log(tmpMostCandidatesAgreed);

    setCandidatesByCategory(tmpCandidatesByCategory)
    setMostCandidatesAgreed(tmpMostCandidatesAgreed)
  }

  const renderCandidate = (candidate: Candidate) => {
    const candidateProposals = proposals.filter((proposal: Proposal) => proposal.candidates.find((candidateProposal) => candidateProposal === candidate.name) && proposal.score !== undefined) as Proposal[]

    return (
        <li className="container__col-6 container__col-xl-2 text-align-center" key={candidate.name}>
          <div style={{
            paddingTop: 35,
            border: "1px solid #CFCED3",
            margin: 12,
            borderRadius: 4,
            cursor: "pointer",
            overflow: "hidden"
          }} onClick={() => {
            props.history.push(ProposalsCandidate.replace(":candidate", candidate.name))
          }}>
            <img src={candidate.photo} style={{width: 81, height: 81, borderRadius: "50%", objectFit: "cover"}}/>
            <h5>{candidate.name}</h5>
            <p>{candidate.group}</p>
            <p className={"pb-3"}>
              <strong>{candidate.score} {candidate.score == 1 || candidate.score == 0 || candidate.score == -1 ? "point" : "points"}</strong>
            </p>
            <div className={"flex"} style={{maxWidth: "100%"}}>
              <div style={{
                height: 8,
                backgroundColor: colors.green_dark,
                flex: candidateProposals.filter((proposal) => proposal.score === 3).length
              }}/>
              <div style={{
                height: 8,
                backgroundColor: colors.green_normal,
                flex: candidateProposals.filter((proposal) => proposal.score === 2).length
              }}/>
              <div style={{
                height: 8,
                backgroundColor: colors.green_light,
                flex: candidateProposals.filter((proposal) => proposal.score === 1).length
              }}/>
              <div style={{
                height: 8,
                backgroundColor: colors.dark[20],
                flex: candidateProposals.filter((proposal) => proposal.score === 0).length
              }}/>
              <div style={{
                height: 8,
                backgroundColor: colors.orange,
                flex: candidateProposals.filter((proposal) => proposal.score === -1).length
              }}/>
              <div style={{
                height: 8,
                backgroundColor: colors.blood_orange,
                flex: candidateProposals.filter((proposal) => proposal.score === -2).length
              }}/>
              <div style={{
                height: 8,
                backgroundColor: colors.red,
                flex: candidateProposals.filter((proposal) => proposal.score === -3).length
              }}/>
            </div>
          </div>
        </li>
    )
  }

  const renderCategory = (category: any) => {
    const candidates: any[] = [];

    for (const candidate of category.candidates) {
      const candidateFilter = candidatesFilter.find((candidateArray) => candidateArray.candidate.name === candidate.name && candidateArray.active)

      if (candidateFilter) {
        candidates.push(candidate);
      }
    }

    return (
        <div className="container__col-6 text-align-center" key={category.name} style={{marginBottom: 104}}>
          <h5 style={{paddingBottom: 40}}>{category.name}</h5>
          <div className={"flex flex-align-center"}>
            <div style={{position: "relative", width: "70%"}}>
              <span style={{position: "absolute", left: "-15%", marginTop: -10, lineHeight: 1.1}}>Pas d'accord<br/>avec vous</span>
              <ul className={"flex flex-align-center rainbow-box"} style={{gap: 16}}>
                {candidates.map((candidate: any) => {
                  if (candidate.score === 0) {
                    return;
                  }

                  const zIndex = candidates.findIndex((candidateInArray: { name: any; }) => candidate.name === candidateInArray.name) + 1
                  const candidatesWithSamePercent = candidates.filter((candidateInArray: { percent: number, nbProposals: number }) => candidate.percent === candidateInArray.percent && candidateInArray.nbProposals > 0)
                  let marginTop = 0

                  if (candidatesWithSamePercent.length > 1) {
                    const position = candidatesWithSamePercent.findIndex((candidateInArray: { name: any; }) => candidate.name === candidateInArray.name) + 1
                    marginTop = 20 * position - 30;
                  }

                  const style = {position: "absolute", top: -24} as CSSProperties;

                  if (candidate.percent < 90) {
                    style.left = candidate.percent + "%"
                  } else {
                    style.right = 100 - candidate.percent + "%"
                  }

                  return (
                      <li style={style} key={candidate.name}>
                        <img src={candidate.photo}
                             style={{
                               width: 56,
                               height: 56,
                               borderRadius: "50%",
                               objectFit: "cover",
                               zIndex: zIndex,
                               border: "2px solid white",
                               top: marginTop,
                               position: "relative",
                             }}/>
                      </li>
                  )
                })}
              </ul>
              <span style={{position: "absolute", right: "-12%", marginTop: -25, lineHeight: 1.1}}>D'accord<br/>avec vous</span>
            </div>
          </div>
        </div>
    )
  }

  const clickOnCandidateFilter = (candidate: any) => {
    const candidateInCandidateFilter = candidatesFilter.find((candidateFilter) => candidateFilter.candidate.name === candidate.candidate.name);

    if (candidateInCandidateFilter) {
      candidateInCandidateFilter.active = !candidateInCandidateFilter.active
    }

    setCandidatesFilter(old => [...old])
  }

  const renderContent = () => {
    if (resultType === "total") {
      return (
          <div>
            <ul style={{marginTop: 48}} className={"container__row"}>
              {candidates.map((candidate: Candidate) => {
                return renderCandidate(candidate)
              })}
            </ul>
          </div>
      )
    }

    return (
        <div>
          <div style={{marginTop: 48}} className={"container__row"}>
            <ul className={"container__col-6 flex flex-align-center"} style={{gap: 8}}>
              {candidatesFilter.map((candidate: any) => {
                return (
                    <li key={candidate.candidate.name}
                        style={{cursor: "pointer", filter: candidate.active ? "grayscale(0)" : "grayscale(1)"}}
                        onClick={() => clickOnCandidateFilter(candidate)}>
                      <img src={candidate.candidate.photo}
                           style={{
                             width: 56,
                             height: 56,
                             borderRadius: "50%",
                             objectFit: "cover",
                           }}/>
                    </li>
                )
              })}
            </ul>
          </div>
          <div style={{marginTop: 40}} className={"container__row"}>
            {candidatesByCategory.map((category: any) => {
              return renderCategory(category)
            })}
          </div>
        </div>
    )
  }

  return (
      <div>
        <div style={{
          backgroundColor: "white",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"
        }}>
          <div className={`container container__row`}>
            <a onClick={async () => {
              props.history.push(Categories)
            }} className={"flex flex-align-center-vertically pt-1 pb-1"} style={{cursor: "pointer"}}>
              <img src={down}/>
              Retour aux categories
            </a>
          </div>
        </div>
        <div style={{backgroundColor: "#F4F4F4"}}>
          <div className="container">
            <div className={`container__row container__row__padding-sm`}>
              <div className={`container__col-6 text-align-center`} style={{paddingTop: 64, paddingBottom: 51}}>
                <h3>Vos résultats</h3>
                <p>{resultType == "total" ? "Les candidats sont classés en fonction de vos affinités toutes catégories confondues" : "Les candidats sont classés en fonction de vos affinités par catégorie"}</p>
                <div className={"flex flex-align-center"} style={{marginTop: 40}}>
                  <a href="#" className={`filter ${resultType !== "total" ? "filter_inactive" : ""}`}
                     style={{marginRight: 16}}
                     onClick={() => {
                       setResultType("total")
                     }}>
                    Vue globale
                  </a>
                  <a href="#" className={`filter ${resultType !== "by-category" ? "filter_inactive" : ""}`}
                     onClick={() => {
                       setResultType("by-category")
                     }}>
                    Détail par catégories
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container container__row__padding-md">
          <div className={`container__row`} style={{marginBottom: 80}}>
            <div className={`container__col-6`}>
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
  )
}

export default withFirebase(ResultsScreen)